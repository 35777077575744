@import '../../scss/mixins';
@import '../../scss/variables';

.footer-wrapper {
  padding-top: 80px;
}

.footer {
  padding: 16px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #222933;
  @include text(12px, white, 600, 14.52px);

  .color-primary {
    color: $color-primary;
  }
}