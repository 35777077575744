@import '../../scss/variables.scss';
@import '../../scss/mixins.scss';

.header {
  padding: 16px 0;
  border-bottom: 1px solid $color-border;
  // background-color: #222933;
  color: white;

  .logout-icon {
    font-size: 28px;
    color: $color-black;
  }

}

.header-strip {
  width: 100%;
  height: 80px;
  background-color: #222933;
  padding: 0 32px;
  color: white;
  position: fixed;
  z-index: 999;

  @media screen and (max-width: 991px) {
    background-color: $color-primary;
  }
}

.header-strip-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #F6F7F9;
}

.header-menu {
  padding: 8px 0px;
  background: #FFFFFF;
  border: 1px solid #C9CACD;
  box-shadow: 0px 0px 32px rgba(62, 73, 70, 0.3);
  border-radius: 4px;

  .item {
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: #E7F6F3;
    }
  }
}

.header-web {
  @media screen and (max-width: 991px) {
    display: none;
  }
}

.header-mobile {
  display: none;

  @media screen and (max-width: 991px) {
    display: block;
  }
}