@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.summary-card {
    @include card();
    padding: 16px 16px 32px 16px;
    height: 160px;

    .red {
        color: red;
    }

    .green {
        color: $color-primary;
    }
}