@import './scss/_mixins.scss';
@import './scss/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.mt-4 {
  margin-top: 4px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-64 {
  margin-top: 64px;
}

.text-center {
  text-align: center;
}

.blue-cta {
  @include cta;
  color: $color-link;
}

.full-width {
  width: 100%;
  max-width: $max-width;
}

.highlight {
  color: $color-primary;
}

.strikethrough {
  text-decoration: line-through;
}

.no-margin-sides {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.center-align {
  margin: 0 auto;
}

.primary-cta {
  color: $color-primary;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.show-on-web {
  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}

.show-on-mobile {
  @media screen and (min-width: calc($mobile-breakpoint + 1px)) {
    display: none;
  }
}