@import '../../scss/mixins';
@import '../../scss/variables';

.color-primary {
    color: $color-primary;
}

.home-links {
    .card {
        padding: 120px 90px;
        box-shadow: 0px 0px 16px rgba(62, 73, 70, 0.3);
        border-radius: 8px;
        transition: 600ms;

        &:hover {
            @include cta();
            box-shadow: 0px 0px 32px rgba(62, 73, 70, 0.3);
            background-color: $color-primary;
        }

        .icon {
            font-size: 48px;
            margin-bottom: 16px;
        }

        &:hover .icon,
        &:hover .title {
            color: white !important;
        }


    }

    .divider {
        height: 250px;
    }
}

.client-name {
    color: $color-primary;
    text-decoration: underline;
}

.chain {
    .block {
        @include card($width: 200px);
        padding: 32px 0;
        @include cta();

        &:hover {
            box-shadow: 0px 0px 32px rgba(62, 73, 70, 0.3);
        }
    }

    .active {
        background-color: $color-primary;

        .secondary-text,
        .title,
        .subheading,
        .primary-text {
            color: white;
        }
    }
}

.block-detail {
    @include card();
    padding: 32px 24px;
    overflow-x: scroll;
}

.select{
    width: 100%;
    border: 1px solid #C9CACD;
    background: white;
    border-radius: 8px;
}