@import './scss/_mixins.scss';
@import './scss/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

html {
  scroll-behavior: smooth;
  font-family: 'Inter';
  font-style: normal;
}

body {
  background-image: url("./assets/icons/BG.png");
  background-attachment: fixed, fixed;
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 100%;

}

.container {
  margin: 0 auto;
  max-width: $max-width;
  // padding-bottom: 32px;
  // padding-left: 24px;
  // padding-right: 24px;
}

.main {
  @include spacing($margin: 0, $padding: (64px 0 96px 0), $mobile-padding: (48px 0 96px 0));
}

.section-heading {
  @include text(32px, $color-black, 600, 38px);
}

.title,
.price {
  @include text(20px, $color-black, 600, 28px);
}

.primary-text {
  @include text(14px, $color-black, 400, 20px);
}

.subheading {
  @include text(18px, $color-black, 400, 27px);
}

.secondary-text {
  @include text(12px, $color-secondary-text, 400, 16px);
  text-transform: uppercase;
}

.secondary-heading {
  @include text(32px, $color-black, 600, 38.4px, 22px, $color-black, 600, 26.4px);
}

.icon-description {
  @include text(12px, $color-black, 400, 16px);
}

.cta {
  // @include text(14px, $color-link, 400, 20px);
  @include cta();
}

.primary-heading {
  @include text(48px, $color-black, 700, 62px, 32px, $color-black, 700, 38.4px);

  @media screen and (max-width: $mobile-breakpoint) {
    text-align: center;
  }
}

.loader-div {
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  text-align: center;
}

.loader {
  padding: 50px;
  border-radius: 4px;
}