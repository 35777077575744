// color 
$color-primary: #11A387;
$color-primary-lighter: #11a38639;
$color-black: #131F2D;
$color-secondary-text: #4B5054;
$color-tertiary-text: #93959B;
$color-cool-grey: #5D636E;
$color-form-label: #5F6467;
$color-link: #3984DA;
$color-border: #C9CACD;
$color-background-primary: rgba(218, 251, 245, 0.2);
$color-background-secondary: rgba(201, 202, 205, 0.2);
$color-background-tertiary: #FAFAFA;
$color-background-footer: #222933;


// width 
$max-width: 1168px;
$mobile-breakpoint: 575px;