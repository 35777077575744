@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.primary {
    @include button();
}

.secondary {
    @include button($border: 1px solid $color-primary, $background-color: white, $color: $color-primary);

    &:hover {
        @include button();
    }
}

.disabled {
    background-color: $color-border;
    cursor: not-allowed;
}

.full-width {
    width: 100%;
}