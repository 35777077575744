@import '../../../scss/variables.scss';


.table-component {

    .table {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
        font-size: 14px;
        line-height: 20px;
        padding: 0 10px 0 10px;
        overflow-wrap: normal;
        white-space: nowrap;
        color: #4B5054;
    }

    .ant-table-thead>tr>th {
        background-color: #F5F5F5;
        height: 40px;
        font-weight: 500;
    }

    .ant-table-tbody>tr>td {
        background-color: white;
        height: 36px;
        font-weight: 400;
        box-shadow: inset 0px -1px 0px #EAEFEE;
    }

    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
    }

}

.pagination {
    background-color: #F5F5F5;
    padding: 6px 40px;

    .ant-pagination-item {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #9EBBC5 !important;
        padding: 0 8px;
    }

    .ant-pagination-item-active {
        color: $color-primary  !important;
        background-color: transparent;
        border: none;
    }

    .ant-pagination-options {
        float: right;
        // padding-right: 24px;
    }

    .ant-pagination-options::before {
        content: "Entries per page";
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #93959B;
        margin-right: 8px;
    }

    .ant-select-selector {
        border: none !important;
        border-radius: 4px !important;
    }
}

.table-cta {
    padding: 20px 0;

    .filter {
        text-align: right;
    }

    .cta {
        flex-direction: row;
        flex: auto;
        justify-content: flex-end;
    }

    .content {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        color: $color-secondary-text;

        &:hover,
        &.active {
            font-weight: 500;
            color: $color-primary;
        }
    }
}

.invisible-divider {
    border: none;
}

.search-input {
    background-color: white;
    // border: none;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    height: 100%;
    align-items: center;
    width: 300px;

    &:focus,
    &:active,
    &:hover,
    &:visited,
    &:link,
    &::backdrop {
        outline: none;
        // border: none !important;
        // border-color: transparent !important;
        box-shadow: none !important;
    }
}