@import '../../scss/mixins';
@import '../../scss/variables';


.login-container {
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;


  .card {
    @include card($width: 480px, $border: none);
    padding: 36px 64px 64px 64px;
    box-shadow: 0px 0px 32px rgba(62, 73, 70, 0.3);
  }
}